import { FC, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { ParsedUrlQueryInput } from 'node:querystring';

import { Link, Props as LinkProps } from '@common/atoms/Link';
import { useContextData } from '@common/useContextData';
import { formatInternalRoute, Route } from '@web/routing';

export interface Props extends Omit<LinkProps, 'href'> {
    route: Route;
    query?: ParsedUrlQueryInput;
}

// TODO: place somewhere else if we need more logic like this
const IS_APP_ROUTER = false;

export const InternalLink: FC<Props> = ({ route, query, children, ...otherProps }) => {
    const { context } = useContextData();
    const __url = useTranslation('url').t;

    const path = useMemo(
        () => formatInternalRoute(route, context.slug, __url, IS_APP_ROUTER ? query : undefined),
        [route, context.slug, __url, query],
    );

    if (IS_APP_ROUTER) {
        return (
            <Link
                href={{
                    pathname: path,
                }}
                {...otherProps}
            >
                {children}
            </Link>
        );
    }

    return (
        <Link
            href={{
                pathname: path,
                query: query,
            }}
            {...otherProps}
        >
            {children}
        </Link>
    );
};
